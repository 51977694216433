import React, { ReactElement, useState, useEffect } from 'react'
import { graphql } from 'gatsby'
import './OurNeighborhoodList.scss'
import Layout from '../../layouts/Layout/Layout'

import NeighborhoodLayout from '../../layouts/NeighborhoodLayout/NeighborhoodLayout'
import Seo from '../../components/Seo/Seo'
import loadable from '@loadable/component'

const NoResult = loadable(() => import('../../components/NoResult/NoResult'))

const SearchInput = loadable(() =>
  import('../../components/SearchInput/SearchInput'),
)
const MiniSliderCard = loadable(() =>
  import('../../components/MiniSliderCard/MiniSliderCard'),
)

export type NeighborhoodTypes = {
  id: string
  title: string
  slug: string
  featuredImage: string
  url: string
}

type OurNeighborhoodPropTypes = {
  data: {
    allNeighborhood: {
      nodes: NeighborhoodTypes[]
    }
  }
}
export const Tabs = [
  { label: 'Neighborhood', url: 'neighborhoods' },
  { label: 'Estates', url: 'estates' },
]

const OurNeighborhoodList = ({
  data: {
    allNeighborhood: { nodes: allNeighborhood },
  },
}: OurNeighborhoodPropTypes): ReactElement => {
  const [featuredImage] = useState<string>(
    allNeighborhood.find((neighborhood) => neighborhood.featuredImage)
      ?.featuredImage || '',
  )
  const [dataList, setDataList] = useState(allNeighborhood)
  const [searchTerm, setSearchTerm] = useState('')

  useEffect(() => {
    setDataList(
      allNeighborhood.filter((data) => {
        if (searchTerm !== '' && data.title.search(searchTerm) < 0) {
          return false
        }

        return true
      }),
    )
  }, [searchTerm, allNeighborhood])

  return (
    <Layout>
      <Seo
        slug="neighborhoods"
        title="Our Neighborhood"
        ogMetaData={{
          title: 'Our Neighborhood',
          description:
            'Beyond sensible features, amenities, and accessibility to other lifestyle essentials, Avida properties offer more opportunities in the vibrant and thriving cities and towns to which they belong. Explore your future neighborhood.',
          image: dataList && dataList[0]?.featuredImage,
        }}
        jsonData={{
          url: `${process.env.GATSBY_SITE_URL}/neighborhoods/`,
          keywords:
            'properties, condo, philippines, house and lot, avidaland, avida, apartment, property list',
        }}
      />
      <div className="our-neighborhood-list">
        <NeighborhoodLayout
          navigationTabs={Tabs}
          background={featuredImage}
          title="Neighborhoods"
          isSearch
          body="Beyond sensible features, amenities, and accessibility to other lifestyle essentials, Avida properties offer more opportunities in the vibrant and thriving cities and towns to which they belong. Explore your future neighborhood."
        >
          <SearchInput
            name="city"
            placeholder="Find exactly what you are looking for"
            setSearchTerm={setSearchTerm}
            className="search-input"
          />
        </NeighborhoodLayout>
        <div className="our-neighborhood-list-content">
          <div className="our-neighborhood-list-content-cards">
            {dataList.length ? (
              dataList.map((data) => (
                <MiniSliderCard
                  key={data.id}
                  data={data}
                  url={`/neighborhoods/${data.slug}`}
                />
              ))
            ) : (
              <NoResult searchTerm={searchTerm} />
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default OurNeighborhoodList

export const pageQuery = graphql`
  query {
    allNeighborhood {
      nodes {
        ...OurNeighborhoodListPageArticleFields
      }
    }
  }
`
