import React, { ReactElement } from 'react'
import './NeighborhoodLayout.scss'

import ContentNavigation from '../../components/ContentNavigation/ContentNavigation'

type NavigationTabTypes = {
  label: string
  url: string
}

type LandingPageProps = {
  navigationTabs: NavigationTabTypes[]
  header?: string
  title: string
  body: string
  isSearch?: boolean
  children?:
    | string
    | Element
    | ReactElement
    | boolean
    | (string | Element | ReactElement | boolean)[]
  background?: string
}

const NeighborhoodLayout = ({
  navigationTabs,
  header,
  title,
  body,
  isSearch,
  children,
  background,
}: LandingPageProps): ReactElement => {
  return (
    <div className="our-neighborhood-layout">
      <ContentNavigation navigations={navigationTabs} center />
      <div
        className="our-neighborhood-layout-banner"
        style={{
          background: `linear-gradient(to bottom, rgba(153, 13, 21, 0.8), rgba(153, 13, 21, 0.8)), url('${background}') no-repeat scroll center center / cover, linear-gradient(to bottom, #990d14d7, #990d14d7)`,
        }}
      >
        <h1 className="our-neighborhood-layout-banner-title">{title}</h1>
        <p className="our-neighborhood-layout-banner-body">{body} </p>
        {isSearch && (
          <div className="our-neighborhood-layout-banner-search-container">
            {children}
          </div>
        )}
      </div>
    </div>
  )
}

export default NeighborhoodLayout
